* {
  /* border: red solid 1px; */
  font-family: tahoma;
  color: white;
  /* font-weight: bolder; */
}


/* background Styling */
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html { 
  background: url(./images/spaceBackground.jpg) repeat-y center top fixed; 
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  overflow-y: visible;
  animation: slide 300s linear infinite;
  overscroll-behavior: none;
  scroll-behavior: smooth;
}

.Twinkle-wrapper {
  background-image: url(./images/dots.png);
  background-size: 5vmin 20vmin;
  width: 100%;
  height: 100%;
  position: fixed;
  background-repeat: repeat;
  /* overflow-y: scroll; */
  display: flex;
  z-index: -1;
  /* i think adding this and removing the overflow-y fixes the extra scrollbar issue */
  overflow: hidden;
}

body {
  overscroll-behavior: none;
}

@keyframes slide {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 0px -2943px;
  }
}


/* Nav menu styling */

.Dropdown {
  position: fixed;
  overflow-x: hidden;
  z-index: 1;
}

.Dropdown-button {
  width: 4vmin;
  height: 6vmin;
  position: fixed;
  right: 5.5vmin;
  top: 2vmin;
  transition: transform 1s ease-in-out;
}

.Dropdown-button:hover {
  transform: rotate(180deg);
}

.Dropdown-content {
  display: block;
  color: white;
  right: -20vmin;
  position: fixed;
  top: 8vmin;
  text-align: center;
  font-weight: bold;
  transition: 1s ease-in-out;
  font-size: 2vmin;
}

.Dropdown-content p {
  border-bottom: .3vmin white solid;
  padding-bottom: 1vmin;
}

.Dropdown:hover .Dropdown-content {
  display: block;
  right: 2.5vmin;
}


/* secret styling */
.Secret-wrapper {
  font-size: 1.4vmin;
  position: fixed;
  left: 2vmin;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: -10px;
  top: 1vmin;
  height: 22vmin;
  z-index: 1;
  font-weight: bolder;
}

#hidden {
  width: auto;
  max-width: 0%;
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 1s;
}

.Secret-wrapper:hover #hidden {
  max-width: 100%;
}

.Secret-wrapper:hover .Warp {
  display: block;
  animation: fadeIn ease 2s;
}

/* .Secret-wrapper:hover::after > .Warp {
  display: block;
  animation: fadeOut ease 2s;
} */

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

.Warp-cropper {
  width: 11vmin;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-radius: 50%;
}

.Warp {
  display: none;
  margin: 0 auto;
  margin-left: -40%;
  width: 20vmin;
  opacity: 1;
}


/* Top title styling */
.Top-title {
  text-align: center;
  font-size: 2vmin;
}

.Top-title p {
  font-size: 1.4vmin;
  position: relative;
  top: -2vmin;
  font-weight: bold;
}


/* Headshot styling */
.Headshot {
  border: white .5vmin solid;
  width: 30vmin;
  height: 30vmin;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  top: 25vmin;
  font-size: 2vmin;
  backdrop-filter: blur(1px);
}

.Headshot img {
  border-radius: 50%;
}

.Headshot h1 {
  width: 100%;
}

.Title {
  width: 100%;
  text-align: center;
  position: relative;
  top: 70vmin;
  font-size: 2vmin;
}

.Wrapper-div {
  height: 80vmin;
  width: 100vmin;
  /* border: blue solid 1px; */
  /* position: absolute; */
}


/* about styling */
.About {
  position: relative;
  width: 100%;
  text-align: center;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  top: 6vmin;
  /* border: blue solid 1px; */
}

/* @media (max-width: ) */

.About-icons {
  /* border: red solid 1px; */
  position: relative;
  width: 50vmin;
  height: 65vmin;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.About-icons-row {
  display: inline-block;
  justify-content: center;
  width: 60vmin;
  /* border: blue solid 1px; */
  position: relative;
  padding-right: 12vmin;
  padding-left: 12vmin;
  /* right: -5vmin; */
}

.About-icons img {
  width: 9vmin;
  height: 9vmin;
  /* border: red solid 1px; */
  margin: 2.5vmin;
  animation: pulse 6s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.About-left {
  top: 2vmin;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: blue solid 1px; */
  width: 65vmin;
  height: fit-content;
  /* border: red solid 1px; */
}

.About-right {
  width: 70vmin;
  position: relative;
  display: flex;
  height: fit-content;
  /* border: red solid 1px; */
  align-items: center;
  justify-content: center;
}

.About-logo {
  position: relative;
  top: -2vmin;
  /* border: blue solid 1px; */
  /* left: 53vmin; */
  /* top: 0vmin; */
}

.About-logo img {
  animation: rotation 60s infinite linear;
  height: 13vmin;
  width: 8vmin;
  /* border: blue solid 1px; */
}
@keyframes rotation {
  from{
    transform: rotate(0deg);
  } 
  to{
    transform: rotate(359deg);
  }
}

.About-text {
  font-size: 2vmin;
  font-weight: bold;
  align-items: center;
  width: 65vmin;
  /* height: 65vmin; */
  word-spacing: 1vmin;
  /* border: blue solid 1px; */
}

.About-text p{
  /* width: 80vmin; */
  /* border: blue solid 1px; */
}

#Github {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(297deg) brightness(105%) contrast(103%);
}

#Express {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(297deg) brightness(105%) contrast(103%);
}


/* Proejcts component styling */
.Projects {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  top: 20vmin;
  width: 100%;
  /* border: blue solid 1px;  */
}

.Projects-title {
  position: relative;
  width: 100%;
  font-size: 4.05vmin;
}

.Projects-content {
  position: relative;
  top: 0vmin;
  width: 100%;
  /* border: red solid 1px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
  justify-content: space-evenly;
  /* height: 200%; */
}

.Projects-image-wrapper {}

.Project-content {
  /* height: 80vmin; */
  /* border: solid purple 1px; */
  width: 80%;
  display: flex;
  flex-direction: row;
  padding-bottom: 5vmin;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* flex: 1 1 0; */
  /* display: -webkit-flex;
  display: -moz-flex; */
}

.Project-contents {
  text-align: center;
  /* border: solid red 1px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Project-content h1{
  font-size: 3vmin;
  width: 25vmin;
  position: relative;
  border-bottom: .5vmin white solid;
  /* left: 24vmin; */
  border-bottom-left-radius: .7vmin;
  border-bottom-right-radius: .7vmin;
}

.Project-content p{
  font-size: 2vmin;
  font-weight: bold;
  align-items: center;
  /* width: 100%; */
  word-spacing: 1vmin;
  padding: 0vmin 3vmin 1vmin;
  /* border: 1px solid red; */
  /* backdrop-filter: blur(1px); */
}

.Project-content img {
  height: 50%;
  width: 50%;
  position: relative;
  border-radius: 1vmin;
  /* flex-basis: 200%; */
  /* border: solid purple 1px; */
  /* border: 1px solid red; */
}

@-moz-document url-prefix() {
  .Project-content img {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 1vmin;
    flex-basis: 200%;
    /* border: solid purple 1px; */
    /* border: 1px solid blue; */
  }
}

.Project-content button {
  background-color: transparent;
  width: 20vmin;
  height: 8vmin;
  border: .2vmin white solid;
  border-radius: 2vmin;
  transition: all .75s ease-in-out;
  bottom: 2vmin;
  position: relative;
  font-size: 2vmin;
  font-weight: bold;
}

#Bc-button {
  bottom: -1vmin;
}

#Freeswan-login {
  position: relative;
  bottom: 3vmin;
}

.Project-content button:hover {
  transform: scale(1.2);
  border: .4vmin white solid;
  backdrop-filter: blur(1px);
}


/* Contact styling */
.Contact {
  position: relative;
  display: flex;
  top: 20vmin;
  align-items: center;
  width: 100%;
  max-width: 100%;
  /* border: solid purple 1px; */
  flex-direction: column;
  height: 58vmin;
  /* border: 1px solid red; */
}

.Contact h1{
  font-size: 3.5vmin;
}

.Contact-form {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: transparent;
  align-items: center;
  font-weight: bold;
  width: 60%;
  height: 42vmin;
  border-radius: .7vmin;
  /* border: .5vmin white solid; */
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  max-width: 100%;
}

.Contact-form textarea {
  position: relative;
  width: 88.5vmin;
  padding: .8vmin;
  font-size: 2vmin;
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; 
  box-sizing: content-box;
  height: 20vmin;
  background-color: transparent;
  border: white 0px solid;
  border-radius: .7vmin;
  top: 2.5vmin;
  /* backdrop-filter: blur(1px); */
  background-attachment: local;
  background-image:
      repeating-linear-gradient(transparent, transparent 30px, white 30px, white 31px, white 31px);
  line-height: 31px;
}

.Contact-form textarea:focus {outline: none}

.Contact-form label {
  position: relative;
  margin-top: .5vmin;
  margin-bottom: .5vmin;
  font-size: 2vmin;
  float: left;
  width: 90vmin;
  color: white;
}

.Contact-form input[type=text] {
  position: relative;
  width: 88vmin;
  padding: .8vmin;
  font-size: 2vmin;
  background-color: transparent;
  border: white 1px solid;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  box-sizing:content-box;
  /* border-radius: .7vmin; */
  top: 1vmin;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.Contact-form input[type=text]:focus {outline: none}

.Contact-form input[type=submit] {
  position: relative;
  width: 20vmin;
  top: 5vmin;
  border-radius: 1vmin;
  cursor: pointer;
  font-size: 2vmin;
  font-weight: bold;
  background-color: transparent;
  height: 8vmin;
  border: .2vmin white solid;
  border-radius: 2vmin;
  transition: all .75s ease-in-out;
}

.Contact-form input[type=submit]:hover {
  transform: scale(1.2);
  border: .4vmin white solid;
  backdrop-filter: blur(1px);
}


::placeholder {
  color: white;
  font-family: tahoma;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 1px solid white;
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}


/* footer styling */
.Footer {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 10vmin;
  width: 100%;
  top: 22vmin;
  border-top: .5vmin white solid;
  justify-content: space-evenly;
  align-items: center;
}

.Footer a {
  transition: transform 1s ease;
  transform: scale(1);
}

.Footer a:hover {
  transition: transform 1s ease-in-out;
  transform: scale(1.2);
}